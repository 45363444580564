<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8">
              <div class="col-xl-12">
                <!--begin: Wizard Form-->
                <Form
                  :form="form"
                  :formResource="formResource"
                  route="unmarried-certificate"
                  purpose="add"
                  title="Tambah Surat Keterangan Belum Kawin"
                />
                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/view/components/unmarried-certificate/Form.vue";
import { getConfiguration } from "@/core/services/jwt.service.js";

export default {
  components: {
    Form,
  },
  data() {
    return {
      form: {
        title: "Surat Keterangan Belum Kawin",
        citizen_id: "",
        citizen_name: "",
        citizen_gender: "",
        citizen_id_card_number: "",
        citizen_birt_place: "",
        citizen_birt_date: "",
        citizen_citizenship: "",
        citizen_profession: "",
        citizen_is_married: "",
        citizen_is_married_display: "",
        citizen_address: "",
        opening_sentences: getConfiguration().letter_opening_sentences,
        main_sentences: "",
        closing_sentences: getConfiguration().letter_closing_sentences,
        letter_type_id: 6,
        resource_shdk: '',
        resource: 1,
      },
      formResource: {
        resource_name: "",
        resource_id_card_number: "",
        resource_birt_place: "",
        resource_birt_date: "",
        resource_profession: "",
        resource_address: "",
        resource_birt_place_date: "",
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Surat Menyurat", route: "/letters" },
      { title: "Keterangan Belum Kawin", route: "/unmarried-certificate" },
      { title: "Tambah" },
    ]);
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 250px !important;
  height: 250px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>